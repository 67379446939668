import {
    KeyValue
} from 'projects/mypay4-fe-common/src/lib/components/my-pay-table-detail/my-pay-table-detail.component';
import {
    ApiInvokerService, ConfigurationService, environment, Mappers
} from 'projects/mypay4-fe-common/src/public-api';
import { Observable } from 'rxjs';

import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { Ente } from '../model/ente';
import { Rendicontazione } from '../model/rendicontazione';
import { RendicontazioneDetail } from '../model/rendicontazione-detail';
import { RicevutaSearch } from '../model/ricevuta-search';

@Injectable({
  providedIn: 'root'
})
export class RendicontazioneService {

  private baseApiUrl: string;

  constructor(
    private apiInvokerService: ApiInvokerService,
    private currencyPipe: CurrencyPipe, 
    conf: ConfigurationService
  ) {
    this.baseApiUrl = conf.getProperty('baseApiUrl', environment);
  }

  get(ente: Ente, iuf: string): Observable<Rendicontazione> {
    const targetUrl = `${this.baseApiUrl}rendicontazione/get/${ente.mygovEnteId}/${iuf}`;
    return this.apiInvokerService.get<Rendicontazione>(
      targetUrl, null, new Mappers({mapperS2C: Rendicontazione, mapperC2S: Rendicontazione})
    );
  }

  search(ente: Ente, searchParams: Rendicontazione): Observable<Rendicontazione[]> {
    const targetUrl = `${this.baseApiUrl}rendicontazione/search/${ente.mygovEnteId}`;
    return this.apiInvokerService.post<Rendicontazione[]>(
      targetUrl, searchParams, null, new Mappers({mapperS2C: Rendicontazione, mapperC2S: Rendicontazione})
    );
  }

  detail(ente: Ente, iuf: string, iur: string): Observable<Rendicontazione> {
    iur = encodeURIComponent(iur);
    const targetUrl = `${this.baseApiUrl}rendicontazione/detail/${ente.mygovEnteId}/${iuf}/${iur}`;
    return this.apiInvokerService.get<Rendicontazione>(
      targetUrl, null, new Mappers({mapperS2C: Rendicontazione, mapperC2S: Rendicontazione})
    );
  }

  filterDetail(ente: Ente, iuf: string, iur: string, searchParams: RicevutaSearch): Observable<Rendicontazione> {
    iur = encodeURIComponent(iur);
    const targetUrl = `${this.baseApiUrl}rendicontazione/detail/${ente.mygovEnteId}/${iuf}/${iur}`;
    return this.apiInvokerService.post<Rendicontazione>(
      targetUrl, searchParams, null, new Mappers({mapperS2C: RendicontazioneDetail, mapperC2S: RendicontazioneDetail})
    );
  }

  mapDataToShow(data: Rendicontazione): KeyValue[] {
    return [
      {key:'ID rendicontazione', value: data.idRendicontazione},
      {key:'ID regolamento', value: data.idRegolamento},
      {key:'Data ora flusso', value: data.dateFlusso?.toFormat('dd/MM/yyyy HH:mm:ss')},
      {key:'Data regolamento', value: data.dataRegolamento?.toFormat('dd/MM/yyyy')},
      {key:'Totale pagamenti', value: data.countTotalePagamenti.toString()},
      {key:'Importo totale', value: this.currencyPipe.transform(data.importoTotale,'EUR','symbol')}
    ];
  }
}
