import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export class CardInfo{
  url: string;
  name: string;
  icon: IconDefinition;
  content: string;

  htmlSafeContent: string;

  constructor(url:string, name:string, icon: IconDefinition, content:string){
    this.url = url;
    this.name = name;
    this.icon = icon;
    this.content = content;
  }
}