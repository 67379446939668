import { ToastrService } from 'ngx-toastr';
import { CookieService, manageError, UserService } from 'projects/mypay4-fe-common/src/public-api';

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    faFacebookSquare, faInstagramSquare, faTwitterSquare, faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  iconPhone = faPhone;
  iconEnvelope = faEnvelope;

  iconFacebook = faFacebookSquare;
  iconInstagram = faInstagramSquare;
  iconYoutube = faYoutubeSquare;
  iconTwitter = faTwitterSquare;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
  }

  showAppInfo(){
    this.userService.getAppInfoString().subscribe(appInfoString => {
      this.toastr.info(appInfoString, 'Versione applicazione',{
        disableTimeOut: true,
        enableHtml: true,
        tapToDismiss: false,
        toastClass: 'ngx-toastr toast-app-info',
      });
      return null;
    }, manageError('Errore recuperando i dati della versione', this.toastr) );
  }

  resetCookieConsent(){
    this.cookieService.resetCookieConsentBar();
  }

  @Output() scrollToMainContent = new EventEmitter();
  scrollToTop(){
    this.scrollToMainContent.emit();
  }
}
