import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

import { WithActions } from '../../../../mypay4-fe-common/src/lib/table/with-actions';

export class AnagraficaUffCapAcc extends WithActions {

  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.Function,'deComboUfficio',(anag: AnagraficaUffCapAcc) => {
      let deUfficio = (!anag.deUfficio || anag.deUfficio.length <= 35) ?
          anag.deUfficio : anag.deUfficio.substring(0, 35) + '...';
      return `${anag.codUfficio} - ${deUfficio}`;
    }),
    new MapperDef(MapperType.Function,'deComboCapitolo',(anag: AnagraficaUffCapAcc) => {
      let deCapitolo = (!anag.deCapitolo || anag.deCapitolo.length <= 35) ?
          anag.deCapitolo : anag.deCapitolo.substring(0, 35) + '...';
      return `${anag.codCapitolo} - ${deCapitolo}`;
    }),
  ]

  mygovAnagraficaUffCapAccId: number;
  mygovEnteId: number;
  codTipoDovuto: string;
  codUfficio: string;
  deUfficio: string;
  flgAttivo: boolean;
  codCapitolo: string;
  deCapitolo: string;
  deAnnoEsercizio: string;
  codAccertamento: string;
  deAccertamento: string;
  dtCreazione: DateTime;
  dtUltimaModifica: DateTime;

  deComboUfficio: string;
  deComboCapitolo: string;
}