import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Directive({
  selector: '[datepickerFormatYyyy]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'yyyy',
      },
      display: {
        dateInput: 'yyyy',
        monthYearLabel: 'MMM/yyyy',
        dateA11yLabel: 'dd/MM/yyyy',
        monthYearA11yLabel: 'MMM/yyyy',
      },
    } },
  ],
})
export class DatepickerFormatYyyyDirective {

  constructor() { }

}

@Directive({
  selector: '[datepickerFormatMmYyyy]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'MM/yyyy',
      },
      display: {
        dateInput: 'MM/yyyy',
        monthYearLabel: 'MMM/yyyy',
        dateA11yLabel: 'dd/MM/yyyy',
        monthYearA11yLabel: 'MMM/yyyy',
      },
    } },
  ],
})
export class DatepickerFormatMmYyyyDirective {

  constructor() { }

}
