import {
    MyPayBaseTableComponent
} from 'projects/mypay4-fe-common/src/lib/components/my-pay-table/my-pay-table.component';

import { Component, OnChanges, OnInit } from '@angular/core';

@Component({
selector: 'my-pay-table-pivot',
templateUrl: '../../../../../'+MyPayBaseTableComponent.templateUrl,
styleUrls: ['./my-pay-table-pivot.component.scss'],
animations: MyPayBaseTableComponent.animations
})
export class MyPayTablePivotComponent<T> extends MyPayBaseTableComponent<T> implements OnInit, OnChanges
{

}
