import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

export class RendicontazioneDetail {
  public static readonly MAPPER_S2C_DEF = ([
    new MapperDef(MapperType.DateTime,'dataEsito','local-date')
  ]);

  public static readonly MAPPER_C2S_DEF = [
    new MapperDef(MapperType.DateTime,'dateEsitoFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateEsitoTo','local-date')
  ];

  dateEsitoFrom: DateTime;
  dateEsitoTo : DateTime;

  tipoDovuto: string;
  iud: string;
  iuv: string;
  iur: string;
  importo: string;
  dataEsito: DateTime;
  pagatoreAnagrafica: string;
  pagatoreCodFisc: string;
  causale: string;
  attestanteAnagrafica: string;
  attestanteCodFisc: string;
  versanteAnagrafica: string;
  versanteCodFisc: string;
}