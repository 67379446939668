<ng-template #helpPageExport>
  <div>
    <p>Tramite questa funzionalità potrai scaricare in locale una copia dei flussi precedentemente prenotati e generati a partire dai dati presenti in MyPivot.<br>
      Potrai ricercare per la data di prenotazione del flusso e per nome , come risultato della ricerca otterrai una lista di Flussi di export generati dal sistema.<br>
      Per ogni flusso di export verranno visualizzate le seguenti informazioni:<br>
      data di prenotazione, versione del tracciato, nome dell’ operatore che ha effettuato la prenotazione, la classificazione del flusso, il nome, la dimensione del file.<br>
      Per ogni flusso di export avrai a disposizione  il menu azioni costituito da 3 punti “…”, cliccando sui 3 punti “…” ti verranno mostrate le azioni permesse che  potrai  selezionare e cliccare per richiederne l’esecuzione al sistema.</p>
  </div>
  </ng-template>


<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}} <my-pay-help [template]="helpPageExport"></my-pay-help></h1>
    </div>

    <div class="w100">
      <form fxFlex novalidate #sForm [formGroup]="searchForm" (ngSubmit)="onSearch()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-form-field fxFlex="100%" fxFlex.gt-sm appearance="{{'appearance'|global}}">
              <mat-label>Nome Flusso</mat-label>
              <input matInput formControlName="nomeFlusso" placeholder="Inserire un testo">
              <mat-error *ngIf="searchFormErrors['nomeFlusso']">{{ searchFormErrors.nomeFlusso }}</mat-error>
          </mat-form-field>
          <!--mat-form-field fxFlex="100%" fxFlex.gt-sm="13em" appearance="{{'appearance'|global}}">
              <mat-label>Data esportazione dal</mat-label>
              <input matInput required formControlName="dateFrom" [matDatepicker]="searchPickerFrom">
              <mat-datepicker-toggle matSuffix [for]="searchPickerFrom"></mat-datepicker-toggle>
              <mat-datepicker #searchPickerFrom></mat-datepicker>
              <mat-error *ngIf="searchFormErrors['dateFrom']">{{ searchFormErrors.dateFrom }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="13em" appearance="{{'appearance'|global}}">
              <mat-label>Data esportazione al</mat-label>
              <input matInput required formControlName="dateTo" [matDatepicker]="searchPickerTo">
              <mat-datepicker-toggle matSuffix [for]="searchPickerTo"></mat-datepicker-toggle>
              <mat-datepicker #searchPickerTo></mat-datepicker>
              <mat-error *ngIf="searchFormErrors['dateTo']">{{ searchFormErrors.dateTo }}</mat-error>
          </mat-form-field-->
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="17em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
            <mat-label>Intervallo data esportazione</mat-label>
            <mat-date-range-input [rangePicker]="pickerDate">
              <input matStartDate formControlName="dateFrom" placeholder="data da">
              <input matEndDate formControlName="dateTo" placeholder="data a">
            </mat-date-range-input>

            <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerDate></mat-date-range-picker>
            <mat-error *ngIf="searchFormErrors['dateFrom']">{{ searchFormErrors.dateFrom }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <div fxFlex></div>
          <button fxFlex="5em" type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
          <button fxFlex="5em" type="submit" mat-flat-button [disabled]="searchForm.invalid || blockingError" color="accent">Cerca</button>
        </div>
      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
            [hasDetail]="false" [paginatorData]="paginatorData" [parentRef]="this">
    </my-pay-table-pivot>
  </div>

</div>


