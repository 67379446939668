<div class="container"
     fxLayout="column"
     fxLayoutAlign.gt-md="space-around center"
     fxLayoutGap="10px">

      <h1 fxFlex class="mat-h1">MyPivot</h1>
      <h2 fxFlex class="mat-h2">Il portale dell'operatore</h2>
      <div *ngIf="!emailValidationNeeded" fxFlex class="text-center">
        <p>Questa utenza ({{username}} - {{fullName}}) non ha accesso alle funzionalità
        dedicate agli operatori ed è stata partanto automaticamente disconnessa.</p>
      </div>
      <div *ngIf="emailValidationNeeded" fxFlex class="text-center">
        <p>Questa utenza ({{username}}) non ha un indirizzo email associato ed è stata partanto automaticamente disconnessa.</p>

        <p>Effettuare autenticazione su MyPay, validare email e riprovare.</p>
      </div>
      <div fxFlex class="text-center">
        <p *ngIf="detailMsg">Dettaglio: {{detailMsg}}</p>
      </div>
      <div fxFlex class="text-center">
        <span></span>
        <p *ngIf="errorUID"><i>Se si contatta l'assistenza, menzionare il codice '{{errorUID}}'</i></p>
      </div>
      <button fxFlex type="button" mat-flat-button (click)="openLoginForm()" color="primary">Accedi</button>

</div>
