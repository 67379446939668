export class FlussoUploadRequest {

  constructor(codTipo: string) {
    this.codTipo = codTipo;
  }

  codTipo: string;
  posDeAnnoBolletta: number;
  posCodBolletta: number;
  posDtContabile: number;
  posDeDenominazione: number;
  posDeCausale: number;
  posNumImporto: number;
  posDtValuta: number;
}