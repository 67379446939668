<ng-template #helpPageImport>
  <div>
    <p>Sezione di gestione flussi import con funzioni di Caricamento di diversi tipi di flusso, Ricerca e Donwload di Flussi caricati.</p>
  </div>
  </ng-template>


<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}} <my-pay-help [template]="helpPageImport"></my-pay-help></h1>
    </div>
    <h2 class="mat-h2">Caricamento nuovo flusso</h2>

    <div class="w100">

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
          <form fxFlex="100%" fxFlex.gt-sm="30%" [formGroup]="uploadForm">
            <mat-form-field fxFlex="100%" appearance="{{'appearance'|global}}">
              <mat-label>Tipo Flusso</mat-label>
              <mat-select formControlName="tipoFlusso" required name="tipoFlusso">
                <mat-option *ngFor="let tipoFlusso of tipiFlusso" [value]="tipoFlusso">
                  {{tipoFlusso.deTipo}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="uploadFormErrors['tipoFlusso']">{{ uploadFormErrors.tipoFlusso }}</mat-error>
            </mat-form-field>
          </form>

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="70%" appearance="{{'appearance'|global}}" floatLabel="always">
            <mat-label>File</mat-label>
            <input matInput readonly [(ngModel)]="fileLabel" placeholder="Selezionare un file">
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="0px" class="dd-container"
          *ngIf="uploadForm.get('tipoFlusso')?.value.codTipo == 'C'">
            <div>
              <p>Giornale di Cassa CSV - Ordinare le colonne</p>
            </div>
            <div *ngIf="!smallScreen" cdkDropList cdkDropListOrientation="horizontal" class="dd-list dd-hor-list" (cdkDropListDropped)="drop($event)" >
              <div class="dd-box dd-hor-box" *ngFor="let column of columnsOrderCassaCSV" cdkDrag>{{ column.desc }}</div>
            </div>
            <div *ngIf="smallScreen" cdkDropList class="dd-list dd-ver-list" (cdkDropListDropped)="drop($event)" >
              <div class="dd-box dd-ver-box" *ngFor="let column of columnsOrderCassaCSV" cdkDrag>{{ column.desc }}</div>
            </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar" style="margin-top: 10px;">
          <div style="display: inline-block">
            <form novalidate #fileForm>
              <input #fileInput aria-label="Seleziona file" class="file-buttons" id="fileinput" [disabled]="formData!=null" (change)="selectFileOnChange($event.target.files)" type="file"/>
            </form>
          </div>
          <button class="file-buttons" aria-label="Seleziona file" type="button" mat-flat-button [disabled]="formData!=null" id="filebutton" (click)="onReset()" color="accent">Seleziona file</button>
          <button class="file-buttons" aria-label="Reset" type="submit" mat-flat-button [disabled]="formData==null" (click)="selectFileOnChange(null)" color="primary">Reset</button>
          <div fxFlex></div>
          <button class="file-buttons" aria-label="Carica file" type="submit" mat-flat-button [disabled]="formData==null || uploadForm.invalid" (click)="uploadFlusso()" color="accent">Carica file</button>
        </div>

    </div>

    <div class="w100" style="margin-top: 20px;">
      <mat-divider></mat-divider>
    </div>

    <h2 class="mat-h2 mt-3">Ricerca flussi caricati</h2>

    <div class="w100">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="30%" appearance="{{'appearance'|global}}">
            <mat-label>Tipo Flusso</mat-label>
            <mat-select formControlName="tipoFlusso" required name="tipoFlusso">
              <mat-option *ngFor="let tipoFlusso of tipiFlusso" [value]="tipoFlusso">
                {{tipoFlusso.deTipo}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formErrors['tipoFlusso']">{{ formErrors.tipoFlusso }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="70%" appearance="{{'appearance'|global}}">
            <mat-label>Nome Flusso</mat-label>
            <input matInput formControlName="nomeFlusso" placeholder="Inserire un testo">
            <mat-error *ngIf="formErrors['nomeFlusso']">{{ formErrors.nomeFlusso }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="15em" appearance="{{'appearance'|global}}">
            <mat-label>Data caricamento da</mat-label>
            <input matInput required formControlName="dateFrom" [matDatepicker]="pickerFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
            <mat-error *ngIf="formErrors['dateFrom']">{{ formErrors.dateFrom }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="15em" appearance="{{'appearance'|global}}">
            <mat-label>Data caricamento a</mat-label>
            <input matInput required formControlName="dateTo" [matDatepicker]="pickerTo">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
            <mat-error *ngIf="formErrors['dateTo']">{{ formErrors.dateTo }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <div fxFlex></div>
          <button fxFlex="10" type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
          <button fxFlex="10" type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>
        </div>

      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
            [hasDetail]="false" [paginatorData]="paginatorData" [parentRef]="this">
    </my-pay-table-pivot>

  </div>

</div>
