<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> Statistiche - {{titleLabel}}</h1>
    </div>

    <div class="w100">
      <form fxFlex novalidate #sForm [formGroup]="searchForm" (ngSubmit)="onSearch()">
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="0px">

          <div fxFlex="100%" fxFlex.gt-sm="30em" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="0px">
            <mat-radio-group formControlName="dtType" layout="row">
              <mat-radio-button value="1" class="md-primary">AAAA</mat-radio-button>
              <mat-radio-button value="2"> MM/AAAA</mat-radio-button>
              <mat-radio-button value="3"> GG/MM/AAAA</mat-radio-button>
            </mat-radio-group>
            <mat-form-field datepickerFormatYyyy *ngIf="searchForm.get('dtType').value==='1'" fxFlex="24%" appearance="{{'appearance'|global}}">
                <mat-label>AAAA</mat-label>
                <input matInput formControlName="dtYearly" [matDatepicker]="dtPickerYearly" [min]="minDate" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="dtPickerYearly"></mat-datepicker-toggle>
                <mat-datepicker #dtPickerYearly startView="multi-year" (yearSelected)="chosenYearly($event, dtPickerYearly)"></mat-datepicker>
                <mat-error *ngIf="searchFormErrors['dtYearly']">{{ searchFormErrors.dtYearly }}</mat-error>
            </mat-form-field>
            <mat-form-field datepickerFormatMmYyyy *ngIf="searchForm.get('dtType').value==='2'" fxFlex="24%" appearance="{{'appearance'|global}}">
                <mat-label>MM/AAAA</mat-label>
                <input matInput formControlName="dtMonthly" [matDatepicker]="dtPickerMonthly" [min]="minDate" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="dtPickerMonthly"></mat-datepicker-toggle>
                <mat-datepicker #dtPickerMonthly startView="year" (monthSelected)="chosenMonthly($event, dtPickerMonthly)"></mat-datepicker>
                <mat-error *ngIf="searchFormErrors['dtMonthly']">{{ searchFormErrors.dtMonthly }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="searchForm.get('dtType').value==='3'" fxFlex="24%" appearance="{{'appearance'|global}}">
                <mat-label>GG/MM/AAAA</mat-label>
                <input matInput formControlName="dtDaily" [matDatepicker]="dtPickerDaily" [min]="minDate" [max]="maxDate">
                <mat-datepicker-toggle matSuffix [for]="dtPickerDaily"></mat-datepicker-toggle>
                <mat-datepicker #dtPickerDaily></mat-datepicker>
                <mat-error *ngIf="searchFormErrors['dtDaily']">{{ searchFormErrors.dtDaily }}</mat-error>
            </mat-form-field>
          </div>

          <div fxFlex="5%"></div>

          <div fxFlex="100%" fxFlex.gt-sm="30em" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px">
            <h2 class="mat-h2 text-center">Seleziona importi da mostrare</h2>
            <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
              <mat-checkbox fxFlex="25%" formControlName="flgPagati" (change)="flgImportiOnChange();">Pagati</mat-checkbox>
              <mat-checkbox fxFlex="25%" formControlName="flgRendicontati" (change)="flgImportiOnChange();">Rendicontati</mat-checkbox>
              <mat-checkbox *ngIf="ente?.flgTesoreria" fxFlex="25%" formControlName="flgIncassati" (change)="flgImportiOnChange();">Incassati</mat-checkbox>
            </div>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="25em" appearance="{{'appearance'|global}}">
            <mat-label>Tipo Dovuto</mat-label>
            <mat-select formControlName="tipoDovuto" name="tipoDovuto" [compareWith]="compareTipoDovuto"
                (selectionChange)="tipoDovutoOnChange($event.value)" typeaheadDebounceInterval="800">
              <mat-option *ngFor="let tipo of tipiDovuto" [value]="tipo">
                {{tipo.deTipo}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="30em" appearance="{{'appearance'|global}}">
            <mat-label>Ufficio</mat-label>
            <mat-select formControlName="ufficio" name="ufficio" [compareWith]="compareUfficio"
                (selectionChange)="ufficioOnChange($event.value)" typeaheadDebounceInterval="800">
              <mat-option *ngFor="let ufficio of uffici" [value]="ufficio">
                {{ufficio.deComboUfficio}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <div fxFlex></div>
          <button fxFlex="5em" type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
          <button fxFlex="5em" type="submit" mat-flat-button [disabled]="searchType === null || searchForm.invalid || blockingError" color="accent">Cerca</button>
        </div>
      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
            [paginatorData]="paginatorData" [parentRef]="this" [hasDetail]=false>
    </my-pay-table-pivot>
  </div>

</div>
