import { DateTime } from 'luxon';
import { MapperDef, MapperType, WithActions } from 'projects/mypay4-fe-common/src/public-api';

export class Segnalazione extends WithActions {

  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'dtInserimento','local-date-time'),
  ];

  id: number;

  classificazione: string;
  iufKey: string; 
  iudKey: string; 
  iuvKey: string;
  
  nota: string;
  attivo: boolean;
  nascosto: boolean;
  utente: string;
  cfUtente: string;
  dtInserimento: DateTime;
}