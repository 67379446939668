import { ToastrService } from 'ngx-toastr';
import { WithTitle } from 'projects/mypay4-fe-common/src/lib/components/with-title';
import {
    FileSizePipe, manageError, OverlaySpinnerService
} from 'projects/mypay4-fe-common/src/public-api';
import { Subscription } from 'rxjs';

import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faListOl } from '@fortawesome/free-solid-svg-icons';

import { Ente } from '../../model/ente';
import { AccertamentoService } from '../../services/accertamento.service';
import { EnteService } from '../../services/ente.service';

@Component({
  selector: 'app-import-massivo',
  templateUrl: './import-massivo.component.html',
  styleUrls: ['./import-massivo.component.scss']
})
export class ImportMassivoComponent implements OnInit, OnDestroy, WithTitle {

  @ViewChild('fileForm', { read: ElementRef }) fileFormElement: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  get titleLabel(){ return "Import massivo" }
  get titleIcon(){ return faListOl }

  private enteSub: Subscription;
  ente: Ente;
  formData: FormData = null;
  blockingError: boolean = false;
  fileLabel: string;

  constructor(
    private enteService: EnteService,
    private accertamentoService: AccertamentoService,
    private toastrService: ToastrService,
    private overlaySpinnerService: OverlaySpinnerService,
    private elementRef: ElementRef,
    private fileSizePipe: FileSizePipe,
    private location: Location,
  ) {
  }

  ngOnInit(): void {
    this.enteSub = this.enteService.getCurrentEnteObs().subscribe(ente => {
      this.ente = ente;
      this.selectFileOnChange(null);
    });
  }

  ngOnDestroy(): void {
    this.enteSub?.unsubscribe();
  }

  uploadImportMassivo(){
    const spinner = this.overlaySpinnerService.showProgress(this.elementRef);
    this.accertamentoService.uploadImportMassivo(this.ente, this.formData).subscribe(data => {
      this.toastrService.success('Il nuovo import massivo caricato correttamente');
      this.overlaySpinnerService.detach(spinner);
    }, manageError('Errore caricando il file', this.toastrService, ()=>{this.overlaySpinnerService.detach(spinner)}) );
  }

  onReset(){
    this.selectFileOnChange(null);
  }

  selectFileOnChange(files: FileList) {
    if (files?.length > 0) {
      const file = files[0]; // Ottieni il primo file selezionato
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
  
      // Verifica che l'estensione sia .zip
      if (fileExtension === 'zip') {
        // Leggi i primi byte del file per verificare se corrispondono all'intestazione di un file ZIP
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const arrayBuffer = fileReader.result as ArrayBuffer;
          const uint8Array = new Uint8Array(arrayBuffer);
          // Controlla i primi byte per verificare se corrispondono all'intestazione di un file ZIP
          if (uint8Array.length >= 4 &&
              uint8Array[0] === 80 && // 'P'
              uint8Array[1] === 75 && // 'K'
              uint8Array[2] === 3 &&  // versione minima
              uint8Array[3] === 4) {  // versione
            // File ZIP valido
            // Procedi con la creazione della FormData
            this.formData = new FormData();
            this.formData.append("file", file);
            this.formData.append("type", "IMPORT_MASSIVO");
            this.fileLabel = file.name + " [" + this.fileSizePipe.transform(file.size) + "]";
          } else {
            // Il file non è un file ZIP valido
            //alert('Il file non è un file ZIP valido.'); 
            manageError('Il file non è un file ZIP valido.', this.toastrService)('');
            this.resetFileInput();
          }
        };
        fileReader.readAsArrayBuffer(file);
      } else {
        // Se l'estensione non è .zip, mostra un messaggio di errore all'utente
        manageError('Tipo di file non consentito. Si prega di caricare un file con estensione .zip.', this.toastrService)('');
        //alert('Tipo di file non consentito. Si prega di caricare un file con estensione .zip.');
        this.resetFileInput();
      }
    } else {
      // Se l'utente non ha selezionato nessun file, reimposta i valori a null e resetta il campo di input del file
      this.resetFileInput();
    }
  }

  resetFileInput() {
    this.formData = null;
    this.fileLabel = null;
    this.fileFormElement?.nativeElement.reset();
  }

  goBack() {
    this.location.back();
  }
}
