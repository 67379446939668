<ng-template #helpPage>
  <div>
    <p>Gestione tipi dovuto esterni.</p>
  </div>
</ng-template>

<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}} <my-pay-help [template]="helpPage"></my-pay-help></h1>
    </div>
    <h2 class="mat-h2">Ricerca ente</h2>

    <div class="w100 mypay-search-form">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSearch()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="20%" appearance="{{'appearance'|global}}">
              <mat-label>Codice IPA</mat-label>
              <input matInput formControlName="codIpaEnte">
              <mat-error *ngIf="formErrors['codIpaEnte']">{{ formErrors.codIpaEnte }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="40%" appearance="{{'appearance'|global}}">
              <mat-label>Nome</mat-label>
              <input matInput formControlName="deNomeEnte">
              <mat-error *ngIf="formErrors['deNomeEnte']">{{ formErrors.deNomeEnte }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="40%" appearance="{{'appearance'|global}}">
            <mat-label>Codice fiscale</mat-label>
            <input matInput formControlName="codFiscale">
            <mat-error *ngIf="formErrors['codFiscale']">{{ formErrors.codFiscale }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <div fxFlex></div>
          <button type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
          <button type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>
        </div>
      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
        [paginatorData]="paginatorData" [hasDetail]=false [parentRef]="this">
    </my-pay-table-pivot>
  </div>
</div>
