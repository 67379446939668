<mat-expansion-panel class="mat-elevation-z0 mypay-search-chips"
[expanded]="searchOpenState"
(opened)="setSearchPanelState(true)"
(closed)="setSearchPanelState(false)">

<mat-expansion-panel-header>
  <mat-panel-title>
    Parametri della ricerca
  </mat-panel-title>
  <mat-panel-description *ngIf="!searchOpenState">
    <mat-chip-list aria-label="Filtri di ricerca" selectable="false">
      <mat-chip *ngFor="let chip of chips; last as isLast" ngClass="{'chip-multiline-last' : isLast}" class="chip-multiline" [removable]="chip.removable" (removed)="removeSearchFilter(chip)">
        <span>
          <span class="chip-content chip-label">{{chip.label}}</span><br>
          <span class="chip-content chip-value">{{chip.value}}</span>
        </span>
        <fa-icon *ngIf="chip.removable" aria-label="Elimina" [icon]="iconTrash" matChipRemove (click)="doNothing()"></fa-icon>
      </mat-chip>
      <mat-chip *ngIf="(chips?.length || 0)===0" class="chip-multiline chip-multiline-last">
        <span>
          <span class="chip-content chip-label">&nbsp;</span><br>
          <span class="chip-content chip-value">Nessun filtro</span>
        </span>
      </mat-chip>
    </mat-chip-list>
  </mat-panel-description>
</mat-expansion-panel-header>

<ng-content></ng-content>

</mat-expansion-panel>
