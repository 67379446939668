<ng-template #helpPageImport>
  <div>
    <p>Per l’Import massivo caricare un archivio .zip contenente un file in formato .csv. Per ulteriori informazioni sulla composizione del file consultare il Manuale Integrazione Ente.</p>
  </div>
</ng-template>

<div class="container">
  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}} <my-pay-help [template]="helpPageImport"></my-pay-help></h1>
    </div>

    <div class="w100 mypay-search-form">

      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
        <mat-form-field fxFlex appearance="{{'appearance'|global}}" floatLabel="always">
          <mat-label>File</mat-label>
          <input matInput readonly [(ngModel)]="fileLabel" placeholder="Selezionare un file">
        </mat-form-field>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
        <div id="fileinput-form">
          <form novalidate #fileForm>
            <input #fileInput class="file-buttons" id="fileinput" [disabled]="formData!=null" (change)="selectFileOnChange($event.target.files)" type="file" aria-labelledby="filebutton"/>
          </form>
        </div>
        <button fxFlex="23%" fxFlex.lt-md="100%" class="file-buttons" type="button" id="filebutton" mat-flat-button color="accent"
            [disabled]="ente?.codIpaEnte === 'R_VENETO' || formData!=null" (click)="onReset()">Seleziona file</button>
        <button fxFlex="13%" fxFlex.lt-md="100%" class="file-buttons" type="submit" mat-flat-button color="primary"
            [disabled]="ente?.codIpaEnte === 'R_VENETO' || formData==null" (click)="selectFileOnChange(null)">Reset</button>
        <div fxFlex></div>
        <button fxFlex="13%" fxFlex.lt-md="100%" class="file-buttons" type="submit" mat-flat-button color="accent"
            [disabled]="ente?.codIpaEnte === 'R_VENETO' || formData==null" (click)="uploadImportMassivo()">Carica file</button>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar" style="margin-top:5%;">
        <button fxFlex="10em" type="button" mat-flat-button (click)="goBack()" color="primary">Torna alla Ricerca</button>
        <div fxFlex></div>
      </div>
    </div>

  </div>
</div>