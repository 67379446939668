import { CardInfo } from 'projects/mypay4-fe-common/src/public-api';
import { Subscription } from 'rxjs';

import { Component, Input, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { EnteService } from '../../services/ente.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-cards',
  templateUrl: './app-cards.component.html',
  styleUrls: ['./app-cards.component.scss']
})
export class AppCardsComponent implements OnInit, OnDestroy {

  filteredCards: CardInfo[] = [];
  enteSelected: boolean = false;
  iconAngleRight = faAngleRight;
  enteChangeSub: Subscription;

  @Input("cards") cards: CardInfo[];

  constructor(
    private menuService: MenuService,
    private domSanitizer: DomSanitizer,
    private enteService: EnteService,
  ) { }

  ngOnInit(): void {
    this.cards.forEach(ci => ci.htmlSafeContent = this.domSanitizer.sanitize(SecurityContext.HTML, ci.content));

    this.enteChangeSub = this.enteService.getCurrentEnteObs().subscribe(ente => {
      setTimeout(() => {
        if(ente){
          this.enteSelected = true;
          this.filteredCards = this.cards?.filter(aCard => this.menuService.isMenuItemAuth(aCard.url)) ?? [];
        } else {
          this.enteSelected = false;
          this.filteredCards = [];
        }          
      }, 0);
    });
  }

  ngOnDestroy(): void {
    this.enteChangeSub?.unsubscribe();
  }
  
}