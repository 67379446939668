import { WithActions } from './../../../../mypay4-fe-common/src/lib/table/with-actions';
import { DateTime } from 'luxon';

export class Tesoreria extends WithActions {
  id: number;
  annoBolletta: string;
  codBolletta: string;
  dtValuta: DateTime;
  dtContabile: DateTime;
  idRendicontazione: string;
  importoTesoreria: number;
  annoCodDocumento: string;
  codDocumento: string;
  annoCodProvvisorio: string;
  codProvvisorio: string;
  ordinante: string
  causale: string;
  codConto: string;

  details: object[];
}