<div class="container" fxFlex fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start center"
    fxLayoutGap="10px">

    <div class="title-row">
        <h1 class="mat-h1 bold">
            <fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}
        </h1>
    </div>

    <app-cards [cards]="cards"></app-cards>

</div>