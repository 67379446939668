  export class FieldBean {
    name: string;
    required: boolean;
    regex: string;
    htmlRender: string;
    htmlClass: string;
    htmlLabel: string;
    htmlPlaceholder: string;
    bindCms: string;
    defaultValue: string;
    insertableOrder: number;
    indexable: boolean;
    renderableOrder: number;
    serchableOrder: number;
    listableOrder: number;
    insertable: boolean;
    renderable: boolean;
    searchable: boolean;
    isListable: boolean;
    isAssociation: boolean;
    isDetailLink: boolean;
    associationField: string;
    minOccurences: number;
    maxOccurences: number;
    groupBy: string;
    extraAttr: any;
    enumerationList: Array<string>;
    subfields: FieldBean[];

    validDependsOn: string;
    validDependsOnUids: string;

    valueDependsOn: string;
    valueDependsOnUids: string;

    hiddenDependsOn: string;
    hiddenDependsOnUids: string;

    mandatoryDependsOn: string;
    mandatoryDependsOnUids: string;

    enabledDependsOn: string;
    enabledDependsOnUids: string;

    errorMessage: string;
    helpMessage: string;
  }