<footer class="footer pb-3 px-5" role="contentinfo">

  <div fxFlex fxLayout="row wrap" fxLayoutAlign="start top" fxLayoutGap="0">
    <div fxFlex="100%" fxFlex.gt-sm="12em" ngClass="text-center my-0" ngClass.gt-sm="text-left my-3">
      <b>Regione Calabria</b>
    </div>

    <div fxFlex="100%" fxFlex.gt-sm="calc ( 100% - 24em )" ngClass="text-center my-0" ngClass.gt-sm="text-center my-3">
      <a class="ml-0" [routerLink]="" (click)="showAppInfo()">Versione</a>
      <a class="ml-4" target="_blank" href="#">Accessibilità</a>
      <a class="ml-4" routerLink="/info/privacy">Privacy</a> 
      <!-- <a class="ml-4" [routerLink]="">Privacy</a>-->
      <a class="ml-4" routerLink="/info/cookie">Informativa Cookie</a> 
      <!-- <a class="ml-4" [routerLink]="">Informativa Cookie</a>-->
      <a class="ml-4" target="_blank" href="https://www.regione.calabria.it">www.regione.calabria.it</a>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="12em" ngClass="text-center my-0" ngClass.gt-sm="text-right my-3">
      <a class="ml-0" [routerLink]="" (click)="resetCookieConsent()">Rimuovi preferenze cookie</a>
    </div>

    <div fxFlex fxLayout="row wrap" fxLayoutAlign="lefet left" fxLayoutGap="0">
      <div fxLayout="row wrap" fxFlex="100%" fxFlex.gt-xs="50%">
        <div fxFlex="nogrow">
          <img src="assets/images/regione-calabria-logo-footer.png" title="Logo Regione Calabria">
        </div>
        <div fxFlex="nogrow">&nbsp;&nbsp;&nbsp;&nbsp;</div>
        <div fxFlex="nogrow">
          <p class="Footer-siteName">Regione Calabria</p>
          <small>SISTEMA GESTIONALE DEI PAGAMENTI ELETTRONICI DELLE ENTRATE DELLA PA</small>
        </div>
      </div>

      <div fxFlex="grow" ngClass="text-right my-0" ngClass.xs="text-left my-3" fxFlex="100%" fxFlex.gt-xs="50%">
        <img src="assets/images/logo-footer-POR.png" title="Logo POR">
      </div>  

    </div>

  </div>

</footer>