import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

export class RiconciliazioneSearch {
  public static readonly MAPPER_C2S_DEF = [
    new MapperDef(MapperType.DateTime,'dateEsitoFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateEsitoTo','local-date'),
    new MapperDef(MapperType.DateTime,'dateUltModFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateUltModTo','local-date'),
    new MapperDef(MapperType.DateTime,'dateRegolFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateRegolTo','local-date'),
    new MapperDef(MapperType.DateTime,'dateContabFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateContabTo','local-date'),
    new MapperDef(MapperType.DateTime,'dateValutaFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateValutaTo','local-date'),
    new MapperDef(MapperType.DateTime,'annoBolletta','yyyy'),
    new MapperDef(MapperType.DateTime,'annoDocumento','yyyy'),
    new MapperDef(MapperType.DateTime,'annoProvvisorio','yyyy'),
  ];
  dateEsitoFrom: DateTime; 
  dateEsitoTo: DateTime; 
  dateUltModFrom: DateTime; 
  dateUltModTo: DateTime; 
  dateRegolFrom: DateTime; 
  dateRegolTo: DateTime; 
  dateContabFrom: DateTime; 
  dateContabTo: DateTime; 
  dateValutaFrom: DateTime; 
  dateValutaTo: DateTime; 
  iud: string; 
  iuv: string; 
  iur: string; 
  codFiscalePagatore: string; 
  anagPagatore: string; 
  codFiscaleVersante: string; 
  anagVersante: string; 
  attestante: string; 
  ordinante: string; 
  idRendicont: string; 
  idRegolamento: string; 
  tipoDovuto: string; 
  conto: string; 
  importoTesoreria: string; 
  causale: string; 
  annoBolletta: number; 
  codBolletta: string; 
  annoDocumento: number; 
  codDocumento: string; 
  annoProvvisorio: number; 
  codProvvisorio: string; 
}