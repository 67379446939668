<div class="container">

    <div fxFlex fxLayout="column" fxLayoutAlign="start center">
  
      <div class="title-row">
        <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
      </div>
  
      <div class="w100 mypay-search-form">
        <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onSubmit()">
  
          <myp-search-chips #mypSearchChips [formDef]="formDef" [form]="form" [parentRef]="this" [removeFilterCallback]="onRemoveFilter">
  
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
              <mat-form-field fxFlex="100%" fxFlex.gt-sm="35%" appearance="{{'appearance'|global}}">
                <mat-label>{{formDef['idRendicontazione'].label}}</mat-label>
                <input matInput formControlName="idRendicontazione" placeholder="Inserire un testo">
                <mat-error *ngIf="formErrors['idRendicontazione']">{{ formErrors.idRendicontazione }}</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="100%" fxFlex.gt-sm="35%" appearance="{{'appearance'|global}}">
                <mat-label>{{formDef['idRegolamento'].label}}</mat-label>
                <input matInput formControlName="idRegolamento" placeholder="Inserire un testo">
                <mat-error *ngIf="formErrors['idRegolamento']">{{ formErrors.idRegolamento }}</mat-error>
              </mat-form-field>
              <mat-form-field fxFlex="100%" fxFlex.gt-sm="30%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
                <mat-label>Intervallo data regolamento</mat-label>
                <mat-date-range-input [rangePicker]="dtRangePickerRegol">
                  <input matStartDate formControlName="dateRegolFrom" placeholder="data da">
                  <input matEndDate formControlName="dateRegolTo" placeholder="data a">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="dtRangePickerRegol"></mat-datepicker-toggle>
                <mat-date-range-picker #dtRangePickerRegol></mat-date-range-picker>
                <mat-error *ngIf="formErrors['dtRangePickerRegol']">{{ formErrors.dtRangePickerRegol }}</mat-error>
              </mat-form-field>
            </div>          
  
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
              <div fxFlex></div>
              <button fxFlex="10" type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
              <button fxFlex="10" type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>
            </div>
  
          </myp-search-chips>
  
        </form>
      </div>
  
      <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
      <div *ngIf="hasSearched && tableData?.length>0" class="w100">
        <p  class="info-text">
          <fa-icon class="font-accent" [icon]="iconInfoCircle"></fa-icon> &nbsp; {{ infoTextBox }}
        </p>
      </div>
      <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns" [parentRef]="this" 
            [paginatorData]="paginatorData" [hasDetail]="hasDetail">
      </my-pay-table-pivot>
  
    </div>
  
  </div>
  
  