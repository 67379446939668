<div *ngIf="breadcrumbs.length>1">
  <nav>
    <ol class="cd-breadcrumb triangle">
      <li *ngFor="let breadcrumb of breadcrumbs; index as index; count as count; first as isFirst; last as isLast"
          [ngClass]="{ 'first': isFirst && !isLast, 'last': isLast && !isFirst, 'firstlast': isFirst && isLast }">
        <a *ngIf="!isLast" (click)="onClickBreadcrumb(breadcrumb, count-index-1)" class="enabled-link">
          <fa-icon *ngIf="breadcrumb.icon" [icon]="breadcrumb.icon" size="lg"></fa-icon>
          {{breadcrumb.label}}
        </a>
        <span *ngIf="isLast">
          <fa-icon *ngIf="breadcrumb.icon" [icon]="breadcrumb.icon" size="lg"></fa-icon>
          {{breadcrumb.label}}
        </span>
      </li>
    </ol>
  </nav>
</div>
<div class="pt-3" *ngIf="breadcrumbs.length<=1"></div>
