import { UserService } from 'projects/mypay4-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private userService: UserService,
    private router: Router) { }

  ngOnInit(): void {
    if(this.userService.isLogged()){
      this.router.navigate(['cards']);
    }
  }

  openLoginForm() {
    this.userService.goToLogin();
  }

}

