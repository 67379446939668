import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';
import { versionInfo } from '../environments/version';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private externalizedConfiguration: {} = {};
  private backendConfig: {};
  private userFromCookie: User;

  constructor(
    private httpBackend: HttpBackend
  ) {}

  bootstrapConfig(): Promise<any> {
    let promise:Promise<void> = new HttpClient(this.httpBackend)
      .get("assets/conf/environment.json")
      .toPromise()
      .then(data => {
        if(data && typeof data === 'object'){
          this.externalizedConfiguration = data;
          console.log('externalized configuration: ',this.externalizedConfiguration );
          return null;
        }
      }, error => {
        if(error?.status==404)
          console.log('missing externalized configuration');
        else
          console.log('error externalized configuration', error);
      })
      .then(() => {
        return new HttpClient(this.httpBackend)
        .post<{}>(this.getProperty('baseApiUrl')+'public/info/config', null)
        .toPromise();
      })
      .then(data => {
        console.log('backend config', data);
        if(data && typeof data === 'object')
          this.backendConfig = data;

        const versionBE = this.backendConfig?.['gitHash']?.substring(0,8);
        const versionFE = versionInfo.gitHash?.substring(0,8);
        const urlSearchParams = new URLSearchParams(document.location.search);
        const versionUrl = urlSearchParams.get("mypivotVersion");
        let skipReload = versionUrl==versionBE || versionBE==versionFE || versionFE==='?';
        console.log('versionFE['+versionFE+'] versionBE['+versionBE+'] versionUrl['+versionUrl+']');
        if(!skipReload){
          urlSearchParams.set('mypivotVersion', versionBE);
          console.log('try reload FE becuse possibly outdated, new searchString['+urlSearchParams.toString()+']');
          document.location.search = urlSearchParams.toString();
        }

        const useAuthCookie = this.getBackendProperty<boolean>('useAuthCookie', false);
        if(useAuthCookie){
          return new HttpClient(this.httpBackend)
          .post<User>(this.getProperty('baseApiUrl')+'checkLoginCookie', null)
          .toPromise().then(user => {
            if(user && typeof user === 'object')
              this.userFromCookie = user;
          }, error => {
            console.log('error checkLoginCookie, ignoring it!', error);
          });
        }
      }, error => {
        console.log('error backend config', error);
      });

    return promise;
  }

  getBackendProperty<T = string>(key: string, defaultValue?: T):T {
    if(this.backendConfig?.hasOwnProperty(key))
      return this.backendConfig[key];
    return defaultValue;
  }

  getProperty<T = string>(key: string, appEnvironment?:object, defaultValue?: T):T {
    if(this.externalizedConfiguration.hasOwnProperty(key))
      return this.externalizedConfiguration[key];

    if(appEnvironment?.hasOwnProperty(key))
      return appEnvironment[key];

    if(environment.hasOwnProperty(key))
      return environment[key];

    return defaultValue;
  }

  getUserFromCookie():User {
    return this.userFromCookie;
  }

}
