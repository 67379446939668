import { WithActions } from './../../../../mypay4-fe-common/src/lib/table/with-actions';

export class VmStatisticaCapitolo extends WithActions {

  codUfficio: string;
  deUfficio: string;
  codTipoDovuto: string;
  deTipoDovuto: string;
  codCapitolo: string;
  deCapitolo: string;
  importoPagato: number;
  importoRendicontato: number;
  importoIncassato: number;
}