<div class="container" fxFlex fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">

    <div class="title-row">
        <h1 class="mat-h1 bold">
            <fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}
        </h1>
    </div>

    <!-- Sezione manualistica -->
    <div *ngIf="enteService != null && enteService.getCurrentEnte() != null" fxFlex fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container>
            <mat-card class="mat-elevation-z0 mat-card-manualistica" [ngClass.gt-sm]="'mat-card-gtsm'" fxFlex="1 1 0" fxLayout="column">
                <div fxLayoutAlign="center">
                    <mat-card-header fxFlex="0 1 auto" fxLayoutAlign="center">
                        <div mat-card-avatar aria-hidden="true">
                            <fa-icon [icon]="iconBook" size="2x"></fa-icon>
                        </div>
                        <mat-card-title style="text-align: left;">Manualistica</mat-card-title>
                    </mat-card-header>
                </div>
                <mat-card-content>
                    <div>
                        <fa-icon [icon]="iconBook" size="1x"></fa-icon>
                        <a mat-button (click)="downloadFile( nomeFileManualeOperatore )">Manuale Operatore</a>
                    </div>
                    <div>
                        <fa-icon [icon]="iconBook" size="1x"></fa-icon>
                        <a mat-button (click)="downloadFile( nomeFileManualeIntegrazione )">Manuale Integrazione Ente</a>
                    </div>
                </mat-card-content>
            </mat-card>
        </ng-container>      
    </div>

    <app-cards [cards]="cards"></app-cards>

</div>