<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div class="w100">

      <mat-expansion-panel class="detail-section mat-elevation-z0" *ngFor="let details of detailsGroups; index as idx;" expanded="true">
        <mat-expansion-panel-header class="detail-header" *ngIf="detailsGroupsLabel[idx]" collapsedHeight="auto">
          <span class="mat-h3 mat-body-strong break-word">{{detailsGroupsLabel[idx]}}</span>
        </mat-expansion-panel-header>
        <div gdGap="10px" gdColumns="repeat(auto-fill, minmax(300px, 1fr))">
          <div *ngFor="let detail of details | detailfilter:detailFilterInclude:detailFilterExclude">
            <span class="mat-body-strong break-word">{{detail.key}}</span><br>
            <span class="break-word">{{detail.value || '&nbsp;'}}</span>
          </div>
        </div>
      </mat-expansion-panel>

      <app-rendicontazione-detail *ngIf="hasDetailRt" [iuf]="elem.idRendicontazione" [iur]="elem.idRegolamento"></app-rendicontazione-detail>

      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 my-3 mat-button-bar">
        <button fxFlex="6em" type="button" mat-flat-button (click)="back()" color="primary">Indietro</button>
        <button fxFlex="12em" type="button" mat-flat-button *ngIf="hasSegnalazione" (click)="gotoStorico()" color="accent">Storico segnalazioni</button>
        <button fxFlex="12em" type="button" mat-flat-button (click)="addSegnalazione()" color="accent">Aggiungi segnalazione</button>
      </div>
    
    </div>

  </div>

</div>