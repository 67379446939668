<div fxFlex fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
  <ng-container *ngIf="filteredCards?.length>0">
    <mat-card *ngFor="let card of filteredCards; let idx=index" class="mat-elevation-z0" [ngClass.gt-sm]="'mat-card-gtsm'" fxFlex="1 1 0" fxLayout="column">
      <mat-card-header fxFlex="0 1 auto">
          <div mat-card-avatar aria-hidden="true"><a tabindex="-1" class="no-decoration" [routerLink]="card.url">
                  <fa-icon [icon]="card.icon" size="2x"></fa-icon></a></div>
          <mat-card-title><a tabindex="0" class="no-decoration" [routerLink]="card.url" id="card-title-{{idx}}">{{card.name}}</a></mat-card-title>
          <mat-card-title class="card-action-right">
              <a mat-button [routerLink]="card.url"><span class="font-bold" aria-labelledby="card-title-{{idx}}">
                  <u>Vai</u>&nbsp;</span><fa-icon [icon]="iconAngleRight"></fa-icon></a>
          </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div [innerHtml]="card.htmlSafeContent"></div>
      </mat-card-content>
      <div fxFlex></div>
    </mat-card>
  </ng-container>

  <div class="pt-3 myp4-center" *ngIf="enteSelected && filteredCards?.length===0">
    <p>Non hai i permessi per visualizzare nessuna delle funzionalità di questa pagina sull'ente selezionato.</p>
  </div>

</div>

