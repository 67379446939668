<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> Statistiche - {{titleLabel}}</h1>
    </div>

    <div class="w100">
      <form fxFlex novalidate #sForm [formGroup]="searchForm" (ngSubmit)="onSearch()">
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="0px">

          <div fxFlex="100%" fxFlex.gt-sm="30em" fxLayout="column" fxLayoutAlign="start" fxLayoutGap="0px">
            <mat-radio-group formControlName="dtType" (change)="changeSearchType($event.value)" layout="row">
              <mat-radio-button value="1" class="md-primary">AAAA</mat-radio-button>
              <mat-radio-button value="2">MM/AAAA</mat-radio-button>
              <mat-radio-button value="3">GG/MM/AAAA</mat-radio-button>
            </mat-radio-group>

            <mat-form-field *ngIf="selectedDateSearchType==='1' || selectedDateSearchType==='2'" appearance="{{'appearance'|global}}">
              <mat-label id="label-DateSearchType">{{dateFormats[selectedDateSearchType].label}}</mat-label>
              <mat-chip-list #chipListDates selectable="false" aria-labelledby="label-DateSearchType">
                <mat-chip
                  *ngFor="let aDate of selectedDates[dateFormats[selectedDateSearchType].type]"
                  [removable]="true"
                  (removed)="chosen(aDate)" [attr.aria-label]="aDate">
                  {{ aDate }}
                  <fa-icon [icon]="iconTrash" matChipRemove></fa-icon>
                </mat-chip>
                <input
                  placeholder="inserire {{dateFormats[selectedDateSearchType].label}}"
                  formControlName="dateFake"
                  [matChipInputFor]="chipListDates"
                  (matChipInputTokenEnd)="chosen($event)"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matDatepicker]="dtPickerDates"
                  [min]="minDate" [max]="maxDate">
              </mat-chip-list>
              <mat-datepicker-toggle matSuffix [for]="dtPickerDates"></mat-datepicker-toggle>
              <mat-datepicker #dtPickerDates [startView]="dateFormats[selectedDateSearchType].view" (opened)="setPeriodOptions(dtPickerDates)"
                (yearSelected)="chosen($event, dtPickerDates)" (monthSelected)="chosen($event, dtPickerDates)" [dateClass]="selectedDateClass"></mat-datepicker>
              <mat-error *ngIf="searchFormErrors['selectedDateFake']">{{ searchFormErrors.selectedDateFake }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="selectedDateSearchType==='3'" fxFlex="100%" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
              <mat-label>Intervallo date</mat-label>
              <mat-date-range-input [rangePicker]="dtRangePickerDaily" [min]="minDate" [max]="maxDate">
                <input matStartDate formControlName="dateFrom" placeholder="data da">
                <input matEndDate formControlName="dateTo" placeholder="data a">
              </mat-date-range-input>
  
              <mat-datepicker-toggle matSuffix [for]="dtRangePickerDaily"></mat-datepicker-toggle>
              <mat-date-range-picker #dtRangePickerDaily></mat-date-range-picker>
              <mat-error *ngIf="searchFormErrors['dateFrom']">{{ searchFormErrors.dateFrom }}</mat-error>
            </mat-form-field>

          </div>

          <div fxFlex="5%"></div>

          <div fxFlex="100%" fxFlex.gt-sm="30em" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="0px">
            <h2 class="mat-h2 text-center">Seleziona importi da mostrare</h2>
            <div style="width: 100%;" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="0px">
              <mat-checkbox fxFlex="25%" formControlName="flgPagati" (change)="flgImportiOnChange();">Pagati</mat-checkbox>
              <mat-checkbox fxFlex="25%" formControlName="flgRendicontati" (change)="flgImportiOnChange();">Rendicontati</mat-checkbox>
              <mat-checkbox *ngIf="ente?.flgTesoreria" fxFlex="25%" formControlName="flgIncassati" (change)="flgImportiOnChange();">Incassati</mat-checkbox>
            </div>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <div fxFlex></div>
          <button fxFlex="5em" type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
          <button fxFlex="5em" type="submit" mat-flat-button [disabled]="isSearchFormInvalid" color="accent">Cerca</button>
        </div>
      </form>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
            [paginatorData]="paginatorData" [parentRef]="this" [showTotalFooter]="true">
    </my-pay-table-pivot>
  </div>

</div>