<app-header></app-header>

<mat-sidenav-container>

  <mat-sidenav #sidenav mode="side">

    <div class="side-user-item">
      <fa-icon [icon]="iconUser" size="3x" class="padding-right side-menu-item" style="vertical-align: middle;"></fa-icon>
      <span *ngIf="userService.isLogged()" class="side-menu-item">Ciao,{{userService.getLoggedUserString()}}</span>
      <a *ngIf="!userService.isLogged()" (click)="openLoginForm()" class="side-menu-item enabled-link"> <span>Accedi</span> </a>
      <button mat-icon-button (click)="closeSidenav()" aria-label="Chiudi Menu" style="margin-left:50px" class="side-menu-item">
        <fa-icon [icon]="iconTimes" size="2x" aria-label="Chiudi Menu" alt="Chiudi menu"></fa-icon>
      </button>
    </div>

    <div *ngIf="userService.isLogged()" class="container" fxLayout="column">

      <a fxFlex (click)="toogleMainMenu()" class="enabled-link">
        <span class="side-user-icon">
          <fa-icon [icon]="iconBars" size="lg"></fa-icon>
        </span>
        <span class="side-menu-item">Menu principale</span>
      </a>

      <!--
      <mat-list fxFlex *ngIf="mainMenuOpen">
        <mat-list-item *ngFor="let item of menuService.getMainMenu()">
          <a [class.active-first]="item.active" (click)="onClickMenu(item)">
            <span class="side-user-icon" class="pr-1">
              <img *ngIf="item.hasImgIcon()" [src]="item.icon" class="side-user-icon-img"/>
              <fa-icon *ngIf="item.hasFAIcon()" [icon]="item.icon"></fa-icon>
            </span>
            <span class="side-menu-item">{{item.getSidebarLabel()}}</span>
          </a>
        </mat-list-item>
      </mat-list>

      <span class="side-menu-item" style="margin-top:30px">MyPivot 4</span>
      -->
      
      <mat-list fxFlex>
        <ng-container *ngFor="let item of menuService.getApplicationMenu()">
          <mat-list-item *ngIf="item.auth" [class.active-first]="item.active" [class.disabled-link]="!item.url" (click)="onClickMenu(item)">
            <span class="side-user-icon">
              <img *ngIf="item.hasImgIcon()" [src]="item.icon" class="side-user-icon-img"/>
              <fa-icon *ngIf="item.hasFAIcon()" [icon]="item.icon"></fa-icon>
            </span>
            <span>{{item.getSidebarLabel()}}</span>
            <fa-icon *ngIf="item.submenu && item.submenu.length > 0" class="chevron" [class.chevron-opened]="item.opened" [icon]="iconChevronRight"></fa-icon>
          </mat-list-item>
          <mat-list *ngIf="item.opened && item.auth && item.submenu && item.submenu.length > 0" class="side-menu-item-submenu">
            <ng-container *ngFor="let subItem of item.submenu">
              <mat-list-item *ngIf="subItem.auth" [class]="subItem.active?'active-first':null" (click)="onClickMenu(subItem)">
                <span class="side-user-icon">
                  <img *ngIf="subItem.hasImgIcon()" [src]="subItem.icon" class="side-user-icon-img"/>
                  <fa-icon *ngIf="subItem.hasFAIcon()" [icon]="subItem.icon"></fa-icon>
                </span>
                  <span>{{ subItem.getSidebarLabel() }}</span>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </ng-container>
      </mat-list>

      <a style="margin-top:30px" (click)="logout()" class="enabled-link">
        <span class="side-user-icon"> <fa-icon [icon]="iconSignOut" size="lg"></fa-icon>
        </span> <span class="side-menu-item">Esci</span>
      </a>

    </div>

  </mat-sidenav>

  <mat-sidenav-content class="mypivot-sidenav-content">
    <div>
      <my-pay-breadcrumbs></my-pay-breadcrumbs>
    </div>
    <div #mainContainerDiv class="main-container py-3"
        fxLayout="row"
        fxLayoutAlign="center start">
        <router-outlet></router-outlet>
    </div>
    <app-footer (scrollToMainContent)="scrollToMainContent()"></app-footer>
  </mat-sidenav-content>

</mat-sidenav-container>
