import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizePipe'
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB'];

  transform(bytes: number | string = 0, precision: number = 2): string {
    if(bytes==null)
      return null;
    bytes = Number(bytes);
    if (isNaN(bytes) || !isFinite(bytes)) return '?';
    let unit = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }
    return bytes.toFixed(unit==0?0:precision) + ' ' + this.units[unit];
  }
}
