<div mat-dialog-title fxLayout="row" fxLayoutAlign="start start">
  <h2>Dettaglio dati</h2>
  <span class="flex-spacer"></span>
  <button mat-flat-button aria-label="Chiudi" mat-dialog-close><fa-icon [icon]="iconTimes" size="lg"></fa-icon></button>
</div>

<mat-dialog-content class="mat-typography">
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <div *ngFor="let details of detailsGroups; index as idx;">
        <div *ngIf="detailsGroupsLabel[idx]" class="mypay-table-detail-section">
          <span class="mat-h3 break-word">{{detailsGroupsLabel[idx]}}</span>
        </div>
        <div gdGap="10px" gdColumns="repeat(auto-fill, minmax(300px, 1fr))">
          <div [ngClass]="{'entire-row':detail.options?.entireRow,'preformatted':detail.options?.preformatted}"
               *ngFor="let detail of details | detailfilter:detailFilterInclude:detailFilterExclude" tabindex="0">
            <span class="mat-body-strong break-word">{{detail.key}}</span><br>
            <span *ngIf="!detail.inHTML" class="break-word">{{detail.value || '&nbsp;'}}</span>
            <div *ngIf="detail.inHTML" class="break-word" [innerHTML]="detail.value"></div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-flat-button color="accent"
    *ngFor="let action of actionColumn.enabledActions(tableId, parentRef, element) | slice:1"
    (click)= "clickAction(action, $event)">
    <span>{{action.tooltip}}</span>
  </button>
</mat-dialog-actions>
