import { WithTitle } from 'projects/mypay4-fe-common/src/lib/components/with-title';
import { CardInfo } from 'projects/mypay4-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

import {
    AccertamentiCapitoliComponent
} from '../../accertamenti-capitoli/accertamenti-capitoli.component';
import {
    AccertamentiComponent as AccertamentiSearchComponent
} from '../../accertamenti/accertamenti.component';

@Component({
  selector: 'app-accertamenti',
  templateUrl: './accertamenti.component.html',
  styleUrls: ['./accertamenti.component.scss']
})
export class AccertamentiComponent implements OnInit, WithTitle {

  get titleLabel(){ return "Accertamenti" }
  get titleIcon(){ return faBriefcase }

  cards: CardInfo[];

  constructor() { }

  ngOnInit(): void {
    this.cards = [
      new CardInfo('/accertamenti/main', AccertamentiSearchComponent.prototype.titleLabel, AccertamentiSearchComponent.prototype.titleIcon, 
        'In questa sezione potrai creare degli accertamenti, aggiornarli associandovi dei pagamenti, chiuderli e consultarli.'),
      new CardInfo('/accertamenti/capitoli', AccertamentiCapitoliComponent.prototype.titleLabel, AccertamentiCapitoliComponent.prototype.titleIcon, 
        'In questa sezione potrai ricercare e visualizzare il dettaglio per ogni capitolo, e creare nuove anagrafiche.'),
    ];
  }

}
