/**
 * @author RB20231106: Parametrizzazione Download documenti
 *
 */

import { WithTitle } from 'projects/mypay4-fe-common/src/lib/components/with-title';
import { CardInfo, manageError, 
  ApiInvokerService, ConfigurationService, environment } from 'projects/mypay4-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import { faHome, faBook, faAngleRight} from '@fortawesome/free-solid-svg-icons';

import { AccertamentiComponent } from '../../cards/accertamenti/accertamenti.component';
import { AdminComponent } from '../admin/admin.component';
import { FlussiComponent } from '../flussi/flussi.component';
import { StatisticheComponent } from '../statistiche/statistiche.component';
import { VisualizzaComponent } from '../visualizza/visualizza.component';

import { EnteService } from '../../../services/ente.service';
import { ToastrService } from 'ngx-toastr';
import { FileSaverService } from 'ngx-filesaver';
import { HttpParams } from '@angular/common/http';
import { Ente } from '../../../model/ente';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, WithTitle {

  localAbilitaLog: boolean = false;
  prefissoLogClassName = "HomeComponent."; 

  get titleLabel(){ return "MyPivot" }
  get titleIcon(){ return faHome }
  cards: CardInfo[];
  iconBook = faBook;
  iconAngleRight = faAngleRight;
  private baseApiUrl: string;
  // RB20231103
  nomeFileManualeIntegrazione : string = "ID_2212_SAC_L5_REGCAL_ESIR_MyPivot4_ManualeIntegrazioneEnte_v1.1.pdf";
  nomeFileManualeOperatore : string = "ID_2212_SAC_L5_REGCAL_ESIR_MyPivot4_ManualeOperatore_v3.0.pdf";

  constructor
  (
    public enteService: EnteService,
    private toastrService: ToastrService,
    private fileSaverService: FileSaverService,
    private apiInvokerService: ApiInvokerService,
    private conf: ConfigurationService
  ) {
      this.baseApiUrl = conf.getProperty('baseApiUrl', environment);
  }

  ngOnInit(): void 
  {
    this.cards = [
      new CardInfo('/visualizzazione', VisualizzaComponent.prototype.titleLabel, VisualizzaComponent.prototype.titleIcon,
        'In questa sezione potrai visualizzare i dati relativi a ciascun elemento coinvolto nella riconciliazione contabile: ' +
        'la rendicontazione, le ricevute telematiche e il giornale di cassa. Potrai inoltre visualizzare il dettaglio di ' +
        'eventuali situazioni anomale che si possono verificare quando la riconciliazione non si chiude correttamente.'),
      new CardInfo('/flussi', FlussiComponent.prototype.titleLabel, FlussiComponent.prototype.titleIcon,
        'In questa sezione potrai importare i flussi relativi a rendicontazioni pagoPA, ricevute telematiche e giornale ' +
        'di cassa. Alcuni di questi flussi vengono caricati automaticamente, altri possono essere importati manualmente. ' +
        'Potrai inoltre esportare i flussi presenti in MyPivot.'),
      new CardInfo('/accertamenti', AccertamentiComponent.prototype.titleLabel, AccertamentiComponent.prototype.titleIcon,
        'In questa sezione potrai gestire i capitoli e gli accertamenti contabili associati ai singoli pagamenti.'),
      new CardInfo('/statistiche', StatisticheComponent.prototype.titleLabel, StatisticheComponent.prototype.titleIcon,
        'In questa sezione potrai effettuare un monitoraggio statistico delle entrate. Sono disponibili varie tipologie di ' +
        'report che possono mostrare la suddivisione degli importi per le seguenti categorie: pagati, rendicontati e incassati. ' +
        'Un parametro comune a tutte le ricerche è l’intervallo temporale.  A partire dagli importi visualizzati è possibile ' +
        'accedere al relativo dettaglio, evidenziato tramite link, ove disponibile. '),
      new CardInfo('/admin', AdminComponent.prototype.titleLabel, AdminComponent.prototype.titleIcon,
        'In questa sezione potrai gestire Tipi Dovuto esterni per i vari enti e abilitare ad essi gli operatori degli enti.'),
    ];

  }
  
  downloadFile(nomeFile: string) 
  {
    this.downloadDocumento(this.enteService.getCurrentEnte(), nomeFile).subscribe(response => {
      const contentDisposition = response.headers.get('content-disposition');
      const fileName = ApiInvokerService.extractFilenameFromContentDisposition(contentDisposition) ?? nomeFile.replace(/^.*[\\\/]/, '');
      const contentType = response.headers.get('content-type') ?? 'application/octet-stream';
      const blob:any = new Blob([response.body], { type: contentType });
      this.fileSaverService.save(blob, fileName);
    }, manageError('Errore durante il download del file', this.toastrService) );
  }
  
  private downloadDocumento(ente: Ente, nomeFile: string): any {
    let params = new HttpParams().append( 'filename', nomeFile );
    return this.apiInvokerService.get<any>( this.baseApiUrl + 'mybox/download/document/' + ente.mygovEnteId, {params: params,observe: 'response',responseType: 'blob'} );
  }

}
