import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

import { WithActions } from '../../../../mypay4-fe-common/src/lib/table/with-actions';

export class SegnalazioneSearch extends WithActions {
  public static readonly MAPPER_C2S_DEF = [
    new MapperDef(MapperType.DateTime,'dtInseritoPrima','local-date'),
    new MapperDef(MapperType.DateTime,'dtInseritoDopo','local-date'),
  ];
  dtInseritoPrima: DateTime;
  dtInseritoDopo: DateTime;
  attivo: boolean;
  nascosto: boolean;
  classificazione: string;
  utente: string;
  iud: string;
  iuv: string;
  iuf: string;
}