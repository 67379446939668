import { UserService } from 'projects/mypay4-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {

  username: String;
  fullName: String;

  errorUID: String;
  detailMsg: String;
  emailValidationNeeded: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
  ) { 
    this.detailMsg = this.router.getCurrentNavigation()?.extras?.state?.msg;
    this.errorUID = this.router.getCurrentNavigation()?.extras?.state?.errorUID;
    console.log('errorUID: '+this.errorUID);

    this.emailValidationNeeded = this.router.getCurrentNavigation()?.extras?.state?.emailValidationNeeded || false;
  }

  ngOnInit(): void {
    this.username = this.userService.getLoggedUser()?.username;
    this.fullName = this.userService.getLoggedUser()?.nome + ' '+this.userService.getLoggedUser()?.cognome;

    setTimeout(()=>this.userService.logout(false), 1000);
  }

  openLoginForm() {
    this.userService.goToLogin();
  }
}
