<div class="container" fxFlex fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start center"
  fxLayoutGap="10px">

  <div class="title-row">
    <h1 class="mat-h1 bold"><fa-icon class="pr-1" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
  </div>

  <div fxShow="false" fxShow.gt-sm class="flex-row-break"></div>

  <mat-card class="mat-elevation-z0" fxFlex="1 1 0" fxLayout="column">
    <mat-card-content>

      <h1>
        <p class="text-center">COOKIE E ALTRI SISTEMI DI TRACCIAMENTO</p>
      </h1>
      
      <div>
        <p>
          I cookies sono piccoli file di testo che i siti visitati inviano al terminale dell’utente, dove vengono memorizzati, per poi essere ritrasmessi agli stessi siti alla visita successiva.
          <br><br>
          Il presente sito fa uso di cookie tecnici di sessione (non persistenti), in modo strettamente limitato a quanto necessario per la navigazione sicura ed efficiente del sito stesso. La memorizzazione dei cookie di sessione nei terminali o nei browser è sotto il controllo dell'utente, sebbene sui server, al termine delle sessioni HTTP, informazioni relative ai cookie restano registrate nei log dei servizi, con tempi di conservazione comunque non superiori ai sette giorni al pari degli altri dati di navigazione.
          <br><br>
          Il sito consente inoltre l’invio di cookie analitici di terze, utilizzando un sistema di raccolta e analisi dei dati web (link informativa/docs su web analytics utilizzato) per misurare l’interazione degli utenti con il sito web, unicamente per effettuare analisi quantitative aggregate, mediante dati in forma anonima, senza identificazione degli utenti. Ad ogni modo l’utente può rifiutare l'utilizzo dei cookie e, in qualsiasi momento, può revocare anche un consenso già fornito, disabilitandoli direttamente dal browser. La disabilitazione dei cookie potrebbe impedire l’utilizzo di alcune funzioni dei servizi forniti dalla terza parte in quanto potrebbero diventare non accessibili e quindi non più visualizzabili. La raccolta e l’uso delle informazioni effettuati dalla terza parte sono regolati dall’informativa protezione dati di quest’ultima.
          <br><br>
          Il presente sito non fa uso di cookie per la profilazione degli utenti, né vengono impiegati altri metodi di tracciamento.
          <br><br>
          In generale è possibile controllare i cookie gestiti dal proprio browser seguendo le indicazioni raggiungibili mediante i seguenti collegamenti. In alcuni browser vi è la possibilità di effettuare impostazioni selettive (sito per sito) per la gestione dei cookie, offrendo in tal modo un controllo più puntuale della propria privacy.
        </p>
        <br>
        <table style="width: 100%;text-align: center;">
          <tbody>
            <tr>
              <td style="border: 1px solid black;"><a href="http://www.apple.com/it/support/mac-apps/safari/">Apple Safari</a></td>
              <td style="border: 1px solid black;"><a href="http://support.google.com/chrome/bin/answer.py?hl=it&hlrm=en&answer=95647">Google Chrome</a></td>
              <td style="border: 1px solid black;"><a href="https://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a></td>
            </tr>
            <tr>
              <td style="border: 1px solid black;"><a href="http://help.opera.com/Windows/10.20/it/cookies.html">Opera</a></td>
              <td style="border: 1px solid black;"><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</a></td>
              <td style="border: 1px solid black;"><a href="http://windows.microsoft.com/it-IT/windows-vista/Block-or-allow-cookies">Microsoft Internet Explorer</a></td>
            </tr>
          </tbody>
        </table>
        <br><br>
        <p>
          È possibile inoltre impostare il proprio browser in modo che invii il messaggio generale di “non effettuare tracciamento” (ove possibile) al sito. Per ulteriori informazioni accedere ai seguenti collegamenti.
        </p>
        <br>
        <table style="width: 100%;text-align: center;">
          <tbody>
            <tr>
              <td style="border: 1px solid black;"><a href="https://support.apple.com/it-it/guide/safari/sfri40732/mac">Apple Safari</a></td>
              <td style="border: 1px solid black;"><a href="https://support.google.com/chrome/answer/114836">Google Chrome</a></td>
              <td style="border: 1px solid black;"><a href="https://support.mozilla.org/it/kb/impedire-tracciamento-siti-web">Mozilla Firefox</a></td>
            </tr>
            <tr>
              <td style="border: 1px solid black;"><a href="http://help.opera.com/Windows/12.10/it/notrack.html">Opera</a></td>
              <td style="border: 1px solid black;"><a href="https://support.microsoft.com/en-us/microsoft-edge/learn-about-tracking-prevention-in-microsoft-edge-5ac125e8-9b90-8d59-fa2c-7f2e9a44d869">Microsoft Edge</a></td>
              <td style="border: 1px solid black;"><a href="http://windows.microsoft.com/it-it/internet-explorer/use-tracking-protection#ie=ie-11">Microsoft Internet Explorer</a></td>
            </tr>
          </tbody>
        </table>
        <br><br>
        <p>
        Per browser diversi da quelli sopra elencati consultare i rispettivi manuali utente.
        </p>
      </div>
    </mat-card-content>
  </mat-card>
</div>