import { UserService } from 'projects/mypay4-fe-common/src/public-api';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router){ }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    const requiresLogin = route.data.requiresLogin || false;
    const auth = !requiresLogin || this.userService.isLogged() &&
                  (!route.data.menuItem?.roles || route.data.menuItem.roles?.some(role => this.userService.isRoleAuthorized(role)));
    //console.log('routing to: '+route+' - auth: '+auth);
    if (!auth) {
      this.router.navigate(['home']);
      return false;
    } else {
      return true;
    }
  }
}
