import { DateTime } from 'luxon';
import { MapperDef, MapperType, WithActions } from 'projects/mypay4-fe-common/src/public-api';

export class FlussoExport extends WithActions {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'dataCaricamento','local-date-time'),
    new MapperDef(MapperType.DateTime,'dataPrenotazione','local-date-time'),
  ];
  idFlusso: number;
  nome: string;
  dataCaricamento: DateTime;
  operatore: string;
  codStato: string;
  stato: string;
  dimensione: number;
  showDownload: boolean;
  dataPrenotazione: DateTime;
  path: string;
  classificazione: string;
  versioneTracciato: string;

  securityToken: string;
}