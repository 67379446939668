import { DateTime } from 'luxon';
import { MapperDef, MapperType, WithActions } from 'projects/mypay4-fe-common/src/public-api';

export class FlussoImport extends WithActions {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'dataCaricamento','local-date-time'),
  ];
  id: number;
  nomeFlusso: string;
  dataCaricamento: DateTime;
  operatore: string;
  deStato: string;
  codStato: string;
  codErrore: string;
  log: string;
  filePathOriginale: string;
  filePathScarti: string;
  showDownload: boolean;
  securityToken: string;
}