import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'my-pay-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  iconTimes = faTimes;
  iconQuestionCircle = faQuestionCircle;

  @Input('template') templateRef: TemplateRef<any>;

  constructor(
    private dialog:MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  openHelp(templateHelpDialog: TemplateRef<any>): void {
    this.dialog.open(templateHelpDialog, {panelClass: 'mypay4-page-help-panel', autoFocus:false, data: {templateRef: this.templateRef}});
  }

}
