import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { UserService } from '../../../../mypay4-fe-common/src/public-api';

@Injectable()
export class ForcedMailValidationGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router){ }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    if(this.userService.isLogged() && this.userService.getLoggedUser().emailValidationNeeded){
      //force to user data page to complete email validation
      console.log('routing to not-authorized path from: ', route.url);
      this.router.navigate(['not-authorized'],{state:{emailValidationNeeded: true}});
      return false;
    }

    return true;
  }

}
