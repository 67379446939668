import { DateTime } from 'luxon';
import { MapperDef, MapperType, WithActions } from 'projects/mypay4-fe-common/src/public-api';

export class FlussoRicevuta extends WithActions {

  public static readonly MAPPER_DEF = [
    new MapperDef(MapperType.DateTime,'dateEsitoFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateEsitoTo','local-date'),
    new MapperDef(MapperType.DateTime,'dtEDatiPagDatiSingPagDataEsitoSingoloPagamento','local-date'),
    new MapperDef(MapperType.Function,'tipoIdUnivocoPagatore',(flusso: FlussoRicevuta) => {
      switch(flusso.codESoggPagIdUnivPagTipoIdUnivoco) {
        case 'F':
          return '(Persona fisica)';
        case 'G':
          return '(Persona giuridica)';
        default:
          return '';
      }
    }),
    new MapperDef(MapperType.Function,'tipoIdUnivocoVersante',(flusso: FlussoRicevuta) => {
      switch(flusso.codESoggVersIdUnivVersTipoIdUnivoco) {
        case 'F':
          return '(Persona fisica)';
        case 'G':
          return '(Persona giuridica)';
        default:
          return '';
      }
    }),
  ]

  codiceIpaEnte: string;
  codIud: string; //IUD
  codRpSilinviarpIdUnivocoVersamento: string; //IUV
  codEDatiPagDatiSingPagIdUnivocoRiscoss: string; //IUR
  //private BigDecimal numEDatiPagImportoTotalePagato; //Importo totale
  numEDatiPagDatiSingPagSingoloImportoPagato: number; //Importo
  dtEDatiPagDatiSingPagDataEsitoSingoloPagamento: DateTime; //Data esito
  deEIstitAttDenominazioneAttestante: string; //Attestante
  codESoggPagAnagraficaPagatore: string; //Anagrafica pagatore
  codESoggPagIdUnivPagCodiceIdUnivoco: string; //CF pagatore
  codESoggPagIdUnivPagTipoIdUnivoco: string; //F('Persona Fisica') or G('Persona Giuridica')
  deEDatiPagDatiSingPagCausaleVersamento: string; //Causale
  codESoggVersAnagraficaVersante: string; //Anagrafica versante
  codESoggVersIdUnivVersCodiceIdUnivoco: string; //CF versante
  codESoggVersIdUnivVersTipoIdUnivoco: string; //F('Persona Fisica') or G('Persona Giuridica')
  deTipoDovuto: string;

  details: object[];

  tipoIdUnivocoPagatore: string;
  tipoIdUnivocoVersante: string;
  deStato: string;
  codFiscalePa1: string;
}