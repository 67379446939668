import { DateTime } from 'luxon';
import { MapperDef, MapperType, WithActions } from 'projects/mypay4-fe-common/src/public-api';

import { FlussoRicevuta } from './flusso-ricevuta';

export class Rendicontazione extends WithActions {
  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'dateFlusso','local-date-time'),
    new MapperDef(MapperType.DateTime,'dataRegolamento','local-date'),
    new MapperDef(MapperType.Currency,'importoTotale'),
  ];
  public static readonly MAPPER_C2S_DEF = [
    new MapperDef(MapperType.DateTime,'dateRegolFrom','local-date'),
    new MapperDef(MapperType.DateTime,'dateRegolTo','local-date')
  ];
  
  idRendicontazione: string;
  idRegolamento: string;
  dateRegolFrom: DateTime;
  dateRegolTo: DateTime;

  dateFlusso: DateTime;
  dataRegolamento: DateTime;
  importoTotale: number;
  countTotalePagamenti: number;

  details: FlussoRicevuta[];
}