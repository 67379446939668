import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Breadcrumb } from '../../model/breadcrumb';
import { MyPayBreadcrumbsService } from '../../services/my-pay-breadcrumbs.service';

@Component({
  selector: 'my-pay-breadcrumbs',
  templateUrl: './my-pay-breadcrumbs.component.html',
  styleUrls: ['./my-pay-breadcrumbs.component.scss']
})
export class MyPayBreadcrumbsComponent implements OnInit {

  breadcrumbs: Breadcrumb[] = null;

  constructor(
    private myPayBreadcrumbsService: MyPayBreadcrumbsService,
    private router: Router,
  ) {
    myPayBreadcrumbsService.getBreadcrumbs().subscribe(breadcrumbs => this.breadcrumbs = breadcrumbs);
  }

  ngOnInit(): void {
  }

  onClickBreadcrumb(breadcrumb: Breadcrumb, goBackFor: number){
    if(goBackFor===0)
      return;
    if(breadcrumb.home){
      this.myPayBreadcrumbsService.resetBreadcrumbs();
      this.router.navigate(['cards']);
    } else {
      this.myPayBreadcrumbsService.goBack(goBackFor);
    }
  }
}
