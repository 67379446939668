import { DateTime } from 'luxon';
import { MapperDef, MapperType } from 'projects/mypay4-fe-common/src/public-api';

import { WithActions } from '../../../../mypay4-fe-common/src/lib/table/with-actions';

export class AccertamentoCapitolo extends WithActions {


  public static readonly MAPPER_S2C_DEF = [
    new MapperDef(MapperType.DateTime,'dtCreazione','local-date-time'),
    new MapperDef(MapperType.DateTime,'dtUltimaModifica','local-date-time'),
  ];

  id: number;
  codTipoDovuto: string;
  deTipoDovuto: string;
  codUfficio: string;
  deUfficio: string;
  deAnnoEsercizio: string;
  codCapitolo: string;
  deCapitolo: string;
  codAccertamento: string;
  deAccertamento: string;
  dtCreazione: DateTime;
  dtUltimaModifica: DateTime;
  flgAttivo: boolean;
  flgAttivoCambiato: boolean;

  details: object[];
  detailsInHTML: boolean;
}