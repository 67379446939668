
export class TipoFlusso {

  constructor(mygovTipoFlussoId: number, codTipo: string, deTipo: string) {
    this.mygovTipoFlussoId = mygovTipoFlussoId;
    this.codTipo = codTipo;
    this.deTipo = deTipo;
  }

  mygovTipoFlussoId: number;
  codTipo: string;
  deTipo: string;
}
