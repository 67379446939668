import { WithTitle } from 'projects/mypay4-fe-common/src/lib/components/with-title';
import { CardInfo } from 'projects/mypay4-fe-common/src/public-api';

import { Component, OnInit } from '@angular/core';
import { faTools } from '@fortawesome/free-solid-svg-icons';

import { EntiComponent } from '../../admin/enti/enti.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, WithTitle {

  get titleLabel(){ return "Back office" }
  get titleIcon(){ return faTools }

  cards: CardInfo[];

  constructor() { }

  ngOnInit(): void {
    this.cards = [
      new CardInfo('/admin/enti', EntiComponent.prototype.titleLabel, EntiComponent.prototype.titleIcon, 
        'In questa sezione potrai visualizzare, creare o eliminare dei codici tipo dovuto esterni, cioè tipi dovuto non '+
        'gestiti da MyPay. Potrai inoltre abilitare gli operatori MyPivot ai tipi dovuto esterni dei rispettivi enti.'),
    ];
  }

}
