<ng-template #helpPage>
  <div>
    <p>Gestione tipi dovuto esterni.</p>
  </div>
</ng-template>

<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}} <my-pay-help [template]="helpPage"></my-pay-help></h1>
    </div>

    <p class="mat-h3">Anagrafica tipo dovuto</p>

    <div class="w100 mypay-search-form">
      <form novalidate #sForm="ngForm" [formGroup]="form" (ngSubmit)="onModify()">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px">
          <mat-form-field fxFlex="100%" fxFlex.gt-sm="30%" appearance="{{'appearance'|global}}">
              <mat-label>Codice tipo dovuto</mat-label>
              <input matInput required formControlName="codTipo">
              <mat-error *ngIf="formErrors['codTipo']">{{ formErrors.codTipo }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="100%" fxFlex.gt-sm="70%" appearance="{{'appearance'|global}}">
              <mat-label>Descrizione</mat-label>
              <input matInput required formControlName="deTipo">
              <mat-error *ngIf="formErrors['deTipo']">{{ formErrors.deTipo }}</mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-3 mat-button-bar">
          <button type="button" mat-flat-button [disabled]="!modified" (click)="onReset()">Annulla</button>
          <button type="submit" mat-flat-button [disabled]="form.invalid || !modified" color="accent">Modifica</button>
          <div fxFlex></div>
        </div>
      </form>
    </div>

    <mat-divider class="mt-3 mb-2 w100"></mat-divider>

    <p *ngIf="tableData?.length==0">Nessun tipo dovuto esterno per questo ente.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
        [paginatorData]="paginatorData" [hasDetail]=false [parentRef]="this">
    </my-pay-table-pivot>
  </div>
</div>
