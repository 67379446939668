import { TableAction } from './table-action';

export class WithActions {
  showFloatingButtons: Map<string,boolean>;
  enabledActions: Map<string,TableAction[]>;

  public static reset(element: WithActions){
    element.enabledActions = null;
  }

  public static toggleFloatingButtons(tableId: string, element: WithActions){
    //do nothing since we are no more using floating buttons for actions, but vertical menu
    return;

    if(!(element.showFloatingButtons instanceof Map))
      element.showFloatingButtons = new Map();
    element.showFloatingButtons.set(tableId, !element.showFloatingButtons.get(tableId));
  }

}
