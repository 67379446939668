<div class="container">

  <div fxFlex fxLayout="column" fxLayoutAlign="start center">

    <div class="title-row">
      <h1 class="mat-h1 bold"><fa-icon class="pr-1" *ngIf="titleIcon" [icon]="titleIcon" aria-hidden="true"></fa-icon> {{titleLabel}}</h1>
    </div>

    <div class="w100">
      <form fxFlex novalidate #sForm [formGroup]="form" (ngSubmit)="onSubmit()">

        <myp-search-chips #mypSearchChips [formDef]="formDef" [form]="form" [parentRef]="this" [removeFilterCallback]="onRemoveFilter">
          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
            
            <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}">
              <mat-label>{{formDef['deNomeAccertamento'].label}}</mat-label>
            <input matInput formControlName="deNomeAccertamento" placeholder="Inserire un testo">
            <mat-error *ngIf="formErrors['deNomeAccertamento']">{{ formErrors.deNomeAccertamento }}</mat-error>
          </mat-form-field>

            <mat-form-field fxFlex="100%" fxFlex.gt-sm="17em" appearance="{{'appearance'|global}}" [floatLabel]="'always'">
              <mat-label>Intervallo Data Ultimo Aggiornamento</mat-label>
              <mat-date-range-input [rangePicker]="pickerDate">
                <input matStartDate formControlName="dateFrom" placeholder="data da">
                <input matEndDate formControlName="dateTo" placeholder="data a">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerDate"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerDate></mat-date-range-picker>
              <mat-error *ngIf="formErrors['dateFrom']">{{ formErrors.dateFrom }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}">
                <mat-label>{{formDef['iuv'].label}}</mat-label>
              <input matInput formControlName="iuv" placeholder="Inserire un testo">
              <mat-error *ngIf="formErrors['iuv']">{{ formErrors.iuv }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="100%" fxFlex.gt-sm="20em" appearance="{{'appearance'|global}}">
              <mat-label>{{formDef['tipoDovuto'].label}}</mat-label>
              <input type="text" matInput formControlName="tipoDovuto" [matAutocomplete]="autoTipoDovuto">
              <mat-autocomplete #autoTipoDovuto="matAutocomplete" [displayWith]="tipoDovutoDisplayFn">
                <mat-option *ngFor="let optionTipoDovuto of tipoDovutoFilteredOptions | async" [value]="optionTipoDovuto">
                  {{optionTipoDovuto.deTipo}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="formErrors['tipoDovuto']">{{ formErrors.tipoDovuto }}</mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="100%" fxFlex.gt-sm="12em" appearance="{{'appearance'|global}}">
              <mat-label>{{formDef['codStato'].label}}</mat-label>
              <mat-select formControlName="codStato" typeaheadDebounceInterval="800">
                <mat-option *ngFor="let stato of anagraficaStati" [value]="stato">
                  {{ stato.codStato }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="formErrors['codStato']">{{ formErrors.codStato }}</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-2 mb-2 mat-button-bar">
            <div fxFlex></div>
            <button fxFlex="5em" type="button" mat-flat-button (click)="onReset()" color="primary">Reset</button>
            <button fxFlex="5em" type="submit" mat-flat-button [disabled]="form.invalid || blockingError" color="accent">Cerca</button>
          </div>
        </myp-search-chips>
      </form>
    </div>

    <div class="w100">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="0px" class="mx-4 mt-2 mb-1 mat-button-bar">
        <div fxFlex></div>
        <button fxFlex="10em" type="button" mat-flat-button (click)="gotoAnagrafica()"  color="primary">Nuova Anagrafica</button>
      </div>
    </div>

    <p *ngIf="hasSearched && tableData?.length==0">Nessun dato trovato con i criteri di ricerca impostati.</p>
    <my-pay-table-pivot #myPayTable [tableData]="tableData" [tableColumns]="tableColumns"
          [paginatorData]="paginatorData" [parentRef]="this">
    </my-pay-table-pivot>
  </div>

</div>