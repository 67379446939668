import {
    ApiInvokerService, ConfigurationService, environment, Mappers
} from 'projects/mypay4-fe-common/src/public-api';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Ente } from '../model/ente';
import { FlussoRicevuta } from '../model/flusso-ricevuta';
import { RicevutaSearch } from '../model/ricevuta-search';

@Injectable({
  providedIn: 'root'
})
export class RicevuteTelematicheService {

  private baseApiUrl: string;

  constructor(
    private apiInvokerService: ApiInvokerService,
    conf: ConfigurationService
  ) {
    this.baseApiUrl = conf.getProperty('baseApiUrl', environment);
  }

  search(ente: Ente, searchParams: RicevutaSearch): Observable<FlussoRicevuta[]> {
    const targetUrl = `${this.baseApiUrl}ricevute-telematiche/search/${ente.mygovEnteId}`;
    return this.apiInvokerService.post<FlussoRicevuta[]>(
      targetUrl, searchParams, null, new Mappers({mapper: FlussoRicevuta})
    );
  }

  getMypayInfo(enteId: number, iuv: string, codFiscalePa1: string = null): Observable<FlussoRicevuta> {
    let targetUrl = `${this.baseApiUrl}ricevute-telematiche/mypayinfo/${enteId}/${iuv}`;
    if(codFiscalePa1)
      targetUrl += `/${codFiscalePa1}`;
    return this.apiInvokerService.get<FlussoRicevuta>(
      targetUrl, null, new Mappers({mapper: FlussoRicevuta})
    );
  }
}
