<div class="container"
     fxLayout="column"
     fxLayoutAlign.gt-md="space-around center"
     fxLayoutGap="10px">

      <h1 fxFlex class="mat-h1">Benvenuto su MyPivot</h1>
      <h2 fxFlex class="mat-h2">Sistema di riconciliazione dei pagamenti collegato al portale MyPay</h2>
      <span fxFlex>Accedi ai servizi di backoffice a disposizione del tuo ente</span>
      <button fxFlex type="button" mat-flat-button (click)="openLoginForm()" color="primary">Accedi</button>

</div>
